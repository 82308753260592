import Link from 'next/link';

function Bento() {
  return (
    //   <section className="home-find root-container mt-0 py-12">
    //   <div
    //     className="home-find__inner grid grid-rows-3 grid-cols-9 grid-flow-col gap-6 w-full"
    //     style={{ maxHeight: '550px' }}
    //   >
    //     {/* Local */}
    //     <div className="campaign col-span-2 row-span-1">
    //       <div className="campaign__inner">
    //         {/* <a
    //           href="/information/delivery?promo_name=click_and_collect&amp;promo_position=homepg_secondary_1"
    //           className="campaign__link"
    //         ></a> */}
    //         <div
    //           className="campaign__image lazy entered loaded"
    //           data-bg="https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco,w_600/shared/other/vans.png"
    //           data-ll-status="loaded"
    //           style={{
    //             backgroundImage:
    //               'url(https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco,w_600/shared/other/vans.png)',
    //           }}
    //         ></div>
    //         <div className="campaign__content">
    //           <div className="campaign__content-inner mb-0">
    //             <h2 className="leading-none mb-4" style={{ fontSize: '28px' }}>
    //               Delivery to even more local shops
    //             </h2>
    //           </div>
    //           <div className="campaign__actions">
    //             <a
    //               href="/information/delivery?promo_name=click_and_collect&amp;promo_position=homepg_secondary_1"
    //               className="cta-btn cta-btn--plain--white"
    //             >
    //               Find Out More
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     {/* TTA */}
    //     <div className="campaign col-span-4 col-start-3 row-span-1">
    //       <div className="campaign__inner">
    //         {/* <a
    //           href="/refer?promo_name=IDL_Refer_A_Friend&promo_position=homepg_secondarybanner?situation=websecondary"
    //           className="campaign__link"
    //         ></a> */}
    //         <div
    //           className="campaign__image lazy entered loaded"
    //           data-bg="https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco,w_700/campaigns/Refer%20a%20Friend/Ironmongery_Refer_a_Friend.jpg"
    //           data-ll-status="loaded"
    //           style={{
    //             backgroundImage:
    //               'url(https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco,w_700/campaigns/Refer%20a%20Friend/Ironmongery_Refer_a_Friend.jpg)',
    //           }}
    //         ></div>
    //         <div className="campaign__content">
    //           <div className="">
    //             <h2 className="leading-none mb-4 text-normal" style={{ fontSize: '28px' }}>
    //               Get up to <span className="text-orange">£10,000</span> instant
    //               <br />
    //               credit with a trade account
    //             </h2>
    //           </div>
    //           <div className="campaign__actions">
    //             <a
    //               href="/refer?promo_name=IDL_Refer_A_Friend&promo_position=homepg_secondarybanner?situation=websecondary"
    //               className="cta-btn cta-btn--plain--white"
    //             >
    //               <span className="cta-btn__inner">Start Now</span>
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     {/* CAM */}
    //     <div
    //       className="campaign col-span-4 col-start-1 row-span-2 row-start-2 overflow-hidden rounded-2xl bg-cover bg-black"
    //       style={{
    //         backgroundImage: `url("https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco,o_50/campaigns/commerical/image-9-mobile.png")`,
    //       }}
    //     >
    //       <section className="p-12">
    //         <div className="home-callout__content">
    //           <div className="home-callout__content-inner">
    //             <p>Get in touch with our commercial accounts team for more information.</p>
    //             <h2 className="mb-6">
    //               Large Projects. <span>Made Simple.</span>
    //             </h2>
    //             <Link href="/commercial?promo_name=commercial_mar21&amp;promo_position=homepg_secondary_1&amp;situation=WebSecondary">
    //               <a className="cta-btn cta-btn--plain--white">
    //                 <span className="text-center block">Find Out More</span>
    //               </a>
    //             </Link>
    //           </div>
    //         </div>
    //       </section>
    //     </div>

    //     {/* Money */}
    //     <div className="campaign col-span-2 col-start-5 row-span-1">
    //       <div className="campaign__inner">
    //         {/* <a
    //           href="/refer?promo_name=IDL_Refer_A_Friend&promo_position=homepg_secondarybanner?situation=websecondary"
    //           className="campaign__link"
    //         ></a> */}
    //         <div
    //           className="campaign__image lazy entered loaded"
    //           data-bg="https://res.cloudinary.com/manutantraders/image/upload/campaigns/Refer%20a%20Friend/Ironmongery_Refer_a_Friend.jpg"
    //           data-ll-status="loaded"
    //           style={{
    //             backgroundImage:
    //               'url(https://res.cloudinary.com/manutantraders/image/upload/campaigns/Refer%20a%20Friend/Ironmongery_Refer_a_Friend.jpg)',
    //           }}
    //         ></div>
    //         <div className="campaign__content">
    //           <div className="campaign__content-inner">
    //             <p>It pays to refer…</p>
    //             <h2>
    //               Money off for you
    //               <span style={{ color: '#fff' }}>& your customer</span>
    //             </h2>
    //           </div>
    //           <div className="campaign__actions">
    //             <a
    //               href="/refer?promo_name=IDL_Refer_A_Friend&promo_position=homepg_secondarybanner?situation=websecondary"
    //               className="cta-btn cta-btn--plain--white"
    //             >
    //               <span className="cta-btn__inner">Refer Now</span>
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     {/* Catalogue */}
    //     <div className="campaign col-span-3 row-span-2">
    //       <div className="campaign__inner">
    //         {/* <a
    //           href="/free-catalogue?promo_name=C136_out_now&amp;promo_position=homepg_secondary_3situation=WebSecondary"
    //           className="campaign__link"
    //         ></a> */}
    //         <div
    //           className="campaign__image lazy entered loaded"
    //           data-bg="https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto/campaigns/ironmongerydirect/Secondary%20Homepage%20Banners/ID-Cat-136-secondary-banner.jpg"
    //           data-ll-status="loaded"
    //           style={{
    //             backgroundImage:
    //               'url(https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto:eco/campaigns/ironmongerydirect/Secondary%20Homepage%20Banners/ID-Cat-136-secondary-banner.jpg)',
    //           }}
    //         ></div>
    //         <div className="campaign__content">
    //           <div className="campaign__content-inner">
    //             <p>Request your FREE copy!</p>
    //             <h2>New Catalogue Out Now</h2>
    //           </div>
    //           <div className="campaign__actions">
    //             <a
    //               href="/free-catalogue?promo_name=C136_out_now&amp;promo_position=homepg_secondary_3situation=WebSecondarye"
    //               className="cta-btn cta-btn--plain--white"
    //             >
    //               <span className="cta-btn__inner">Request your FREE copy!</span>
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     {/* Money */}
    //     <div className="campaign col-span-5 col-start-5 row-span-1 row-start-3 overflow-hidden rounded-2xl">
    //       <img src="https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto,w_1500/price-drop.jpg" />
    //     </div>
    //   </div>
    // </section>
    <>
      <section className="home-find p-0">
        <div className="home-find__inner flex flex-wrap lg:flex-no-wrap flex-row lg:gap-6 w-full">
          <div className="flex flex-initial order-1 w-full sm:w-1/2 lg:w-2/6 sm:pr-3 lg:pr-0">
            {/* CAM */}
            <div
              className="campaign w-full h-auto bg-grey-darkestx1 rounded-2xl bg-cover bg-center hp-shadow hp-zoom"
              style={{
                backgroundImage: `url("https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto,o_30/campaigns/commerical/image-9-mobile.png")`,
              }}
            >
              <div className="w-full p-8 xxl:p-10 relative h-full flex items-center">
                <div className="px-10 text-center">
                  <div className="hp-eyebrow text-white">Commercial Accounts</div>
                  <h2 className="text-26px xl:text-30px mb-4 leading-tight twrap-balance">
                    <span className="text-orange">Large Projects.</span>
                    <br />
                    Made Simple.
                  </h2>
                  <p className="text-16px xl:text-18px leading-tight mb-6 twrap-balance">
                    Get in touch with our commercial accounts team for more information today!
                  </p>
                  <a
                    href="/commercial?promo_name=commercial_mar21&amp;promo_position=homepg_secondary_1&amp;situation=WebSecondary"
                    className="cta-btn cta-btn--plain--white hover:bg-grey-darkestx1"
                  >
                    <span>Find Out More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-initial order-3 lg:order-2 flex-col sm:flex-row lg:flex-col w-full lg:w-2/6 gap-6 mt-6 lg:mt-0">
            {/* Local */}
            <div
              className="campaign w-full h-auto bg-grey-darkestx1 rounded-2xl bg-cover bg-center hp-shadow hp-zoom"
              style={{
                aspectRatio: '2/1',
                backgroundImage:
                  'url(https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto,w_400,o_20/shared/other/vans.png)',
              }}
            >
              <div className="w-full p-8 xxl:p-10 relative h-full">
                <div className="hp-eyebrow hidden xxl:block">Click &amp; Collect</div>
                <h2 className="text-24px mb-12 leading-none">
                  <span className="text-orange">6,500</span> locations nationwide
                </h2>
                <a
                  href="/information/delivery?promo_name=click_and_collect&amp;promo_position=homepg_secondary_1"
                  className="cta-btn cta-btn--plain--white absolute bottom-0 mb-10 hidden lg:inline-flex"
                >
                  <span>Find Out More</span>
                </a>
              </div>
            </div>

            {/* Money */}
            <a href="mailto: quotes@ironmongerydirect.com">
              <div
                className="campaign w-full h-auto rounded-2xl hp-shadow hp-zoom bg-cover bg-no-repeat bg-center"
                style={{
                  aspectRatio: '2/1',
                  backgroundImage:
                    'url(https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto,w_600,c_fill/v1707229071/campaigns/ironmongerydirect/Secondary%20Homepage%20Banners/2024/Trade-quotes-Secondary-Banner-800x400px.jpg',
                }}
              />
            </a>
            {/* <div
              className="campaign w-full h-auto bg-grey-darkestx1 rounded-2xl hp-shadow hp-zoom bg-cover bg-no-repeat bg-center"
              style={{
                aspectRatio: '2/1',
                backgroundImage:
                  'url(https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto,w_600,c_fill,g_east,o_20,ar_1.5/campaigns/Refer%20a%20Friend/Ironmongery_Refer_a_Friend.jpg)',
              }}
            >
              <div className="w-full p-8 xxl:p-10 relative h-full">
                <div className="hp-eyebrow hidden xxl:block">It pays to refer</div>
                <h2 className="text-24px mb-12 leading-none">
                  <span className="text-orange">Get money off</span>
                  <br />
                  when you tell a friend
                </h2>
                <a
                  href="/refer?promo_name=IDL_Refer_A_Friend&promo_position=homepg_secondarybanner?situation=websecondary"
                  className="cta-btn cta-btn--plain--white absolute bottom-0 mb-10 hidden lg:inline-flex"
                >
                  <span>Find Out More</span>
                </a>
              </div>
            </div> */}
          </div>

          <div className="flex flex-initial order-2 lg:order-3 w-full sm:w-1/2 lg:w-2/6 sm:pl-3 lg:pl-0">
            {/* Catalogue */}
            <div
              className="campaign w-full h-auto bg-grey-darkestx1 rounded-2xl hp-shadow hp-zoom"
              style={{ aspectRatio: '1/1' }}
            >
              <div className="flex flex-row w-full h-full flex-no-wrap overflow-hidden justify-center relative">
                <div className="mt-40">
                  <img
                    src="https://img-cdn.manutantraders.com/image/upload/v1722438803/ironmongery/homepage/Homepage_banners_2024/HB_Aug_24/C138-Secondary-Banner.jpg"
                    loading="lazy"
                    className="object-cover w-full h-full"
                    alt="b1"
                  />
                </div>
                <div className="absolute w-full p-8 xxl:p-10">
                  <div className="hp-eyebrow">Your project starts here</div>
                  <h2 className="text-26px mb-4 leading-none">
                    Pre-order our <span className="text-orange">NEW catalogue</span>
                  </h2>
                  <Link href="/free-catalogue?promo_name=C138_out_now&amp;promo_position=homepg_secondary_3situation=WebSecondarye">
                    <a
                      href="/free-catalogue?promo_name=C138_out_now&amp;promo_position=homepg_secondary_3situation=WebSecondarye"
                      className="cta-btn cta-btn--plain--white"
                    >
                      <span>Pre-order now!</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-1/6 hidden">
            {/* Local */}
            <div className="campaign w-full h-auto bg-grey-darkestx1 rounded-2xl hp-shadow hp-zoom">
              <div
                className=""
                style={{
                  backgroundImage:
                    'url(https://img-cdn.manutantraders.com/image/upload/f_auto,q_auto,w_300,ar_0.5,c_fill/shared/other/vans.png)',
                }}
              ></div>
              <div className="w-full p-10">
                <div className="hp-eyebrow">Click &amp; Collect</div>
                <h2 className="text-24px mb- leading-none">
                  <span className="text-orange">6,500</span> collection points nationwide
                </h2>
                <a
                  href="/free-catalogue?promo_name=C138_out_now&amp;promo_position=homepg_secondary_3situation=WebSecondarye"
                  className="cta-btn cta-btn--plain--white"
                >
                  <span>
                    Get your <strong>FREE</strong> copy!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Bento;
