function CampaignTakeover(): JSX.Element {
  const bannerImages = {
    desktop:
      'https://img-cdn.manutantraders.com/image/upload/v1722504711/ironmongery/homepage/Homepage_banners_2024/HB_Aug_24/Briton-Door-Closers-Desktop-2377x800px-Optimised.jpg',
    tablet:
      'https://img-cdn.manutantraders.com/image/upload/v1722523751/ironmongery/homepage/Homepage_banners_2024/HB_Aug_24/Briton-Door-Closers-Tablet-768x277px.jpg',
    mobile:
      'https://img-cdn.manutantraders.com/image/upload/v1722523751/ironmongery/homepage/Homepage_banners_2024/HB_Aug_24/Briton-Door-Closers-Mobile-360x311px.jpg',
  };

  return (
    <div className="root-container px-8 my-16 sm:px-12 xl:px-32">
      <div className="flex flex-col md:flex-row md:space-x-5">
        <div className="z-10 w-full md:w-3/4 flex">
          <a
            href={
              '/search?query=BritonPromo&utm_source=Homepage&utm_medium=Primary+Banner&utm_campaign=BritonPromo'
            }
          >
            <img
              src={bannerImages.desktop}
              alt="event-desktop"
              className="w-full h-full mx-auto rounded-2xl hidden xl:block"
            />
            <img
              src={bannerImages.tablet}
              alt="event-tablet"
              className="w-full h-full mx-auto rounded-2xl hidden md:block xl:hidden"
            />
            <img
              src={bannerImages.mobile}
              alt="event-mobile"
              className="w-full h-full mx-auto rounded-2xl block md:hidden"
            />
          </a>
        </div>
        <div className="w-full md:w-1/4 flex">
          <a href="/search?query=PricesDropped&utm_source=Homepage&utm_medium=Primary+Banner+Small&utm_campaign=PriceDrops4">
            <img
              src="https://img-cdn.manutantraders.com/image/upload/w_500/v1722840749/ironmongery/homepage/Homepage_banners_2024/HB_Aug_24/Price-Drops-4.0-web-915x997.jpg"
              className="rounded-2xl mt-5 md:mt-0 mx-auto w-full"
              alt="small-banner"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default CampaignTakeover;
